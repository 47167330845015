<template>
  <div>
    <vx-card title="Mail Relance Projet en Cours" class="mb-4">
      <div slot="no-body">

  <div class="aab-vie-content">
          <div>
            <vs-tabs>
              <vs-tab label="Atlantique Bouclier Quiétude ">
                <div>
                  <aab-vie-mails
                    name="admin_Setting_MailRelaunchCurrentProject_atlantiqueBouclierQuiétude"
                  >
                  </aab-vie-mails>
                </div>
              </vs-tab>
              <vs-tab label=" Atlantique Prévoyance Familiale ">
                <div>
                  <aab-vie-mails name="admin_Setting_MailRelaunchCurrentProject_atlantiquePrévoyanceFamille"> </aab-vie-mails>
                </div>
              </vs-tab>
              <vs-tab label="Atlantique Horizon Retraite">
                <div>
                  <aab-vie-mails
                    name="admin_Setting_MailRelaunchCurrentProject_atlantiqueHorizonRetraite"
                  >
                  </aab-vie-mails>
                </div>
              </vs-tab>
              <vs-tab label="Atlantique Avenir Enfant">
                <div>
                  <aab-vie-mails
                    name="admin_Setting_MailRelaunchCurrentProject_atlantiqueAvenirEnfant"
                  >
                  </aab-vie-mails>
                </div>
              </vs-tab>
              <vs-tab label="Atlantique Assistance Funérailles">
                <div>
                  <aab-vie-mails
                    name="admin_Setting_MailRelaunchCurrentProject_atlantiqueAssistanceFunérailles"
                  >
                  </aab-vie-mails>
                </div>
              </vs-tab>
            </vs-tabs>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>





<script>
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import global from "@/config/global";
import { mixin } from "@/mixins/mixin";

export default {
  components: {
    ClipLoader
  },
  data() {
    return {
      role: {},
    };
  },
  created() {
    Validator.localize("fr", VeeValidateFR);
    this.global = global;
    if (this.user.type != "super_administrator_sys" && !this.user.person.userAcces.chk_mail_client) {
      this.$router.push({ name: "Home" })
    }
  },
  mounted() {  },
  computed: {
    ...mapGetters("auth", ["user", "userId"]),
  },
  methods: { 
  }
};
</script>

<style></style>
